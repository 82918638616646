<template>
  <div>
    <!-- <CarouselComponent /> *149-->

    <section>
      <article class="accommodation_article_1">
        <div class="container">
          <div class="accommodation_article1_inner_section">
            <div id="cf">
              <img
                class="top"
                src="../assets/big_images/food - kitchen3.jpg"
              />
            </div>
            <div class="accommodation_article1_description">
              <div class="row">
                <div class="col-md-6">
                  <h1>Fine Dining Restaurant in Kidepo Valley National Park</h1>
                  <p>
                    Choose to dine at the main restaurant, at our special viewing tower,
                    an option of dining by our two different power places ,
                    by the pool or if your are feeling be a little adventurous opt for a
                    bush meal set in a special location surrounded by the raw Kidepo Valley wilderness
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article
        class="mt-5"
        style="border: 1px solid transparent; "
      >
        <div
          class="
        container"
          style="margin: 0px auto 50px auto; text-align: left"
        >
          <div class="dining_grid">
            <div
              class="dining_flex"
              v-for="dining in dining_features"
              :key="dining.id"
            >
              <div>
                <div class="dining_grid_image">
                  <img :src=" dining.image " />
                </div>
                <div class="dining_features">
                  <div class="mt-3">
                    <p
                      style="font-weight: 600; font-size: 1.2em"
                      data-aos="fade-up"
                      data-aos-duration="3000"
                    >
                      {{ dining.title }}
                    </p>
                    <p
                      style="font-weight: 300"
                      data-aos="fade-up"
                      data-aos-duration="3000"
                    >
                      {{ dining.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
// import CarouselComponent from "../components/dining/carousel-component.vue";

export default {
  // components: { CarouselComponent },

  data() {
    return {
      table_drawer: false,
      direction: "rtl",
      feature_photo: require("../assets/big_images/cottages/cottage1.jpg"),

      dining_features: [
        {
          id: "1",
          title: "Restaurant",
          description:
            "Restaurant, “a foodie’s heaven” where you have access to a varied menu covering a selection of carefully chosen cuisines",
          image: require("../assets/big_images/898A9903.jpg"),
        },
        {
          id: "2",
          title: "Dining by the swimming pool",
          description:
            "Relax and sip an exotic drink or do business over a cup of coffee at the Penguin Bar. With a view overlooking the hotels swimming pool",
          image: require("../assets/big_images/898A9854.jpg"),
        },
        {
          id: "3",
          title: "Dining by the Fire place",
          description:
            "Choose to enjoy your dinner by the fireplace. We have 2 fireplaces for you to choose from. Our fireplace in the main lounge is perfect for rainy evenings, giving you a sense of being outside while still enjoying the comfort of being in the cozy lounge.",
          image: require("../assets/big_images/898A9759.jpg"),
        },
        {
          id: "4",
          title: "Bar.",
          description:
            "Restaurant, “a foodie’s heaven” where you have access to a varied menu covering a selection of carefully chosen cuisines",
          image: require("../assets/big_images/898A9662.jpg"),
        },
        {
          id: "5",
          title: "Barista",
          description:
            "Relax and sip an exotic drink or do business over a cup of coffee at the Penguin Bar. With a view overlooking the hotels swimming pool",
          image: require("../assets/images/barista.jpeg"),
        },
        {
          id: "6",
          title: "Meet The Chef.",
          description:
            "Our experienced, organized and detail-oriented Adere Head Chef with a track record of creating, preparing, presenting fine dining meals which are tasty, healthy and nutritious.",
          image: require("../assets/images/Dines/dining2.jpeg"),
        },
      ],
    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#cf {
  position: relative;
  height: 80vh;
  width: 100%;
  margin: 0 auto;
}

#cf img {
  position: absolute;
  left: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.accommodation_article_1 {
  padding-top: 66px;
}
.accommodation_article1_inner_section {
  position: relative;
  border-radius: 10px;
}
.accommodation_article1_inner_section img {
  height: 80vh;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
  border-radius: 10px;
}
.accommodation_article1_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 50px;
  color: white;
}

.accommodation_article1_description h1 {
  font-size: 2.5em;
  font-weight: 600;
}

.accommodation_article1_description p {
  font-size: 1em;
}

.accommodation_facilities {
  position: absolute;
  bottom: -60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accommodation_facilities_desc {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 20px;
}

.theFacilities {
  display: flex;
  align-items: center;
}

.theFacilities > div {
  margin: 0px 10px 0px 10px;
}

.theFacilities > div p {
  margin: 0px;
  padding: 0px;
  font-size: 0.6em;
  font-weight: 600;
  color: grey;
}

.theFacilities .theIcon {
  height: 30px;
  width: 30px;
  background: #eee;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theFeaturedPhotos {
  display: flex;
  align-items: center;
}

.theFeaturedPhotos input {
  display: none;
}
.theFeaturedPhotos > label {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  margin: 0px 10px 0px 10px;
  cursor: pointer;
}
.theFeaturedPhotos > label img {
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 5px;
}
.dining_introduction {
  width: 60%;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.dining_flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.dining_flex > div:nth-child(3) {
  border-radius: 0px 0px 5px 5px;
}

.dining_grid > div .dining_grid_image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.goBtn {
  color: white;
  font-weight: 600;
  transition: 0.5s;
}

.dining_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  #cf {
    position: relative;
    height: 70vh;
    width: 100%;
    margin: 0 auto;
  }
  .accommodation_article1_inner_section img {
    height: 70vh;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
    border-radius: 10px;
  }
  .accommodation_article1_description {
    padding: 10px;
  }

  .accommodation_article1_description h1 {
    font-size: 2em;
  }
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  #cf {
    position: relative;
    height: 70vh;
    width: 100%;
    margin: 0 auto;
  }
  .accommodation_article1_inner_section img {
    height: 70vh;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
    border-radius: 10px;
  }
  .accommodation_article1_description {
    padding: 10px;
  }

  .accommodation_article1_description h1 {
    font-size: 2em;
  }

  .dining_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
}
</style>
