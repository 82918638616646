<template>
  <div>
    <br /><br /><br /><br />
    <section>
      <article class="container mt-5">
        <div>
          <h1 style="font-size: 1.6em; font-weight: 600; text-align: left;">Adere Safari Lodges Gallery</h1>
          <hr />
        </div>
        <div class="locus_gallery">
          <div
            class="demo-image__preview"
            v-for="(list, index ) in srcList"
            :key="index"
          >
            <el-image
              :src="list"
              :preview-src-list="srcList"
            >
            </el-image>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcList: [
        require("../assets/images/bg1.jpg"),
        require("../assets/big_images/The_pool.jpg"),
        require("../assets/big_images/View_Tour.jpg"),
        require("../assets/images/TOK_4313.jpg"),
        require("../assets/big_images/pool1.jpg"),
        require("../assets/big_images/Evn_Pool.jpg"),
        require("../assets/big_images/Fire_Place.jpg"),
        require("../assets/big_images/Wildness.jpg"),
        require("../assets/big_images/Zibers.jpg"),
        require("../assets/big_images/wtt.jpg"),
        require("../assets/images/Kidepo-Valley-National-Park3.jpg"),
        require("../assets/images/rothschild-giraffe-700x466-1.jpg"),
        require("../assets/images/Karimojong.jpg"),
        require("../assets/images/dine1.jpg"),
      ],
    };
  },

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.locus_gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 100px;
}

.demo-image__preview {
  background-color: #3d290a;
  height: 300px;
}
/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .locus_gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 100px;
  }

  .demo-image__preview {
    background-color: #3d290a;
    height: 200px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .locus_gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 100px;
  }

  .demo-image__preview {
    background-color: #3d290a;
    height: 200px;
  }
}
</style>

<style>
.locus_gallery .demo-image__preview .el-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.locus_gallery .demo-image__preview .el-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .locus_gallery .demo-image__preview .el-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .locus_gallery .demo-image__preview .el-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .locus_gallery .demo-image__preview .el-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .locus_gallery .demo-image__preview .el-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}
</style>
