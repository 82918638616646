<template>
  <div style="background-color:#F9F7F3;">
    <section>
      <article class="accommodation_article_1">
        <div class="container">
          <div class="accommodation_article1_inner_section">
            <div id="cf">
              <img
                class="top"
                :src="feature_photo"
              />
            </div>
            <div class="accommodation_article1_description">
              <div class="row">
                <div class="col-md-6">
                  <h1>Enjoy your dream vacation at Adere</h1>
                  <p>
                    A warm welcome awaits you, a cost room and fire place cannot wait to say hello, our restaurant serving delicious meals overseeing our expansive blue swimming pool with a well-stocked bar remain on standby by and nothing will beat the views you are going to experience at every waking moment during your stay…
                  </p>
                </div>
              </div>
            </div>

            <div class="accommodation_facilities">
              <div class="accommodation_facilities_desc">
                <div>
                  <div>
                    <h2 style="font-size: 1.0em; font-weight:600;">Facilities</h2>
                    <div class="theFacilities">
                      <div class="text-center">
                        <div class="w-100 d-flex align-items-center justify-content-center">
                          <div class="theIcon">
                            <!-- <i class="fa fa-person-swimming"></i> -->
                            <i class="el-icon-s-operation"></i>
                          </div>
                        </div>
                        <p>Swimming</p>
                      </div>
                      <div>
                        <div class="w-100 d-flex align-items-center justify-content-center">
                          <div class="theIcon">
                            <i class="fa fa-wifi"></i>
                          </div>
                        </div>
                        <p>Wi-Fi</p>
                      </div>
                      <div>
                        <div class="w-100 d-flex align-items-center justify-content-center">
                          <div class="theIcon"><i class="el-icon-sunny"></i></div>
                        </div>
                        <p>Game Drives</p>
                      </div>
                      <div>
                        <div class="w-100 d-flex align-items-center justify-content-center">
                          <div class="theIcon">
                            <i class="el-icon-knife-fork"></i>
                          </div>
                        </div>
                        <p>Dinner</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <h2 style="font-size: 1.0em; font-weight:600;">Featured Photos</h2>
                    <div class="theFeaturedPhotos">

                      <input
                        type="radio"
                        v-for="(photo, index) in feature_photos"
                        :key="index"
                        :id="index"
                        :name="photo.image"
                        :value="photo.image"
                        v-model="feature_photo"
                      >
                      <label
                        v-for="(photo, index) in feature_photos"
                        :key="index"
                        :for="index"
                      >
                        <img :src="photo.image" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="cottages_article">
        <h2
          class="text-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >Entire cottage rooms of choice</h2>
        <div class="container">
          <div class="cottages_article_container">
            <div class="cottages_article_background"></div>
            <div class="cottages_grid_section">
              <div class="cottages_grid">

                <div
                  v-for="room in rooms"
                  :key="room.id"
                >
                  <div>
                    <el-carousel height="260px">
                      <el-carousel-item
                        v-for="image in room.images"
                        :key="image"
                      >
                        <img
                          :src="image"
                          class="d-block w-100"
                          alt="..."
                        />
                      </el-carousel-item>
                    </el-carousel>
                  </div>

                  <div class="d-flex align-items-center justify-content-between">
                    <div class="room_desc">
                      <p><strong>{{ room.title }}</strong></p>
                      <p style="color: green; font-weight: 600;">{{ room.room_price }}</p>
                      <hr style="margin: 0px;" />
                      <p style="color:grey;">
                        <small>{{ room.room_type }}</small>
                      </p>
                    </div>
                    <div>
                      <el-button
                        type="primary"
                        round
                        size="mini"
                        class="pl-4 pr-4"
                        @click="accommodationDetail(room.id)"
                      >Book</el-button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="surprises_article">
        <div class="container">
          <div class="surprises_article_grid">
            <div class="pr-3 d-flex align-items-center justify-content-center">
              <div>
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Adere Surprises</h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  We are committed to making your stay with us memorable for all the right reasons:
                </p>
                <ul>
                  <li
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    An option of deluxe or standard rooms – we have room for everyone!
                  </li>
                  <li
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    Arguably the best swimming pool and views in the region
                  </li>
                  <li
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    Just next door to Adere, experiences the renown Karamajong culture first hand next door to us after your game drive
                  </li>
                  <li
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    Perfect to host your next corporate event in our newly built African style conference room or be a little
                    adventurous with your proposal or intimate destination wedding celebrations
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div class="surprise_background">
                <div>
                  <div class="surprise_hints">
                    <input
                      type="radio"
                      v-for="(photo, index) in surprise_photos"
                      :key="index+'sh'"
                      :id="index+'sh'"
                      :name="photo.image"
                      :value="photo.image"
                      v-model="selected_surprise"
                    >
                    <label
                      v-for="(photo, index) in surprise_photos"
                      :key="index+'sh'"
                      :for="index+'sh'"
                    >
                      <img :src="photo.image" />
                    </label>
                  </div>
                  <p
                    class="text-center "
                    style="font-size: 0.8em;"
                  >
                    View Adere Surprises
                  </p>
                </div>
              </div>
              <div class="surprise_video">
                <img :src="selected_surprise">
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="relaxing_article">
        <div class="container">
          <div class="relaxing_grid">
            <div>
              <div class="relax_image1">
                <img
                  src="../assets/big_images/cottages/img1.jpg"
                  alt=""
                >
              </div>
              <div class="relax_image2">
                <img
                  src="../assets/big_images/cottages/img2.jpg"
                  alt=""
                >
              </div>
              <div class="relax_image3">
                <img
                  src="../assets/big_images/cottages/img3.jpg"
                  alt=""
                >
              </div>
            </div>
            <div>
              <div>
                <small
                  style="color:green; font-weight: 600;"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Welcome to Adere Safari Lodges</small><br />
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Relaxing Pleasure</h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Plenty intimate spaces created across the property to allow you and yours to feel like you are alone and one with the very special African wilderness.
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Adere Bush breakfast and dinners when you visit during your honeymoon getaway or anniversary will be unmatched.
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="photo_article">
        <div class="container">
          <img
            class="top"
            :src="feature_photo"
          />
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      table_drawer: false,
      direction: "rtl",
      feature_photo: require("../assets/big_images/cottages/cottage1.jpg"),
      selected_surprise: require("../assets/big_images/The_pool.jpg"),

      feature_photos: [
        { image: require("../assets/big_images/cottages/cottage1.jpg") },
        { image: require("../assets/images/bg2.jpg") },
        { image: require("../assets/images/bg3.png") },
        { image: require("../assets/images/dine5.jpg") },
      ],
      surprise_photos: [
        { image: require("../assets/big_images/The_pool.jpg") },
        { image: require("../assets/big_images/Fire_Place.jpg") },
        { image: require("../assets/big_images/Zibers.jpg") },
        { image: require("../assets/big_images/Wildness.jpg") },
      ],

      rooms: [
        {
          id: "1",
          room_code: "Single Room",
          title: "Single Room",
          room_type: "Standard Cottage",
          room_price: "$400",
          description:
            "Ideal for Families or long staying guests, the Kookaburra Cottage features three bed rooms en-suite, a cosy lounge, separate dining area and a kitchen.",
          images: [
            require("../assets/big_images/cottages/double_room.jpg"),
            require("../assets/big_images/cottages/double_room1.jpg"),
            require("../assets/big_images/cottages/double_room2.jpg"),
          ],
        },
        {
          id: "2",
          title: "Double Room",
          room_code: "Double Room",
          room_type: "Deluxe Cottage",
          room_price: "$700",
          description:
            "Double Room offer large king size beds and garden views from the private balconies. Ideal for business stays, this room category features an expansive workspace and a relaxation area with a comfortable couch and coffee table.",
          images: [
            require("../assets/big_images/cottages/double_room.jpg"),
            require("../assets/big_images/cottages/double_room1.jpg"),
            require("../assets/big_images/cottages/double_room2.jpg"),
          ],
        },
        {
          id: "3",
          room_code: "Family Cottage",
          title: "Family Cottage",
          room_type: "Family Cottage",
          room_price: "$900",
          description:
            "Spacious Deluxe Rooms are elegantly appointed and feature views of the hotel’s water gardens from both the room and private balcony. Furnished with one king or two twin beds",
          images: [
            require("../assets/big_images/cottages/double_room.jpg"),
            require("../assets/big_images/cottages/double_room1.jpg"),
            require("../assets/big_images/cottages/double_room2.jpg"),
          ],
        },
      ],
    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },

    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#cf {
  position: relative;
  height: 80vh;
  width: 100%;
  margin: 0 auto;
}

#cf img {
  position: absolute;
  left: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.accommodation_article_1 {
  padding-top: 66px;
}
.accommodation_article1_inner_section {
  position: relative;
  border-radius: 10px;
}
.accommodation_article1_inner_section img {
  height: 80vh;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
  border-radius: 10px;
}
.accommodation_article1_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  color: white;
}

.accommodation_article1_description h1 {
  font-size: 2.5em;
  font-weight: 600;
}

.accommodation_article1_description p {
  font-size: 1em;
}

.accommodation_facilities {
  position: absolute;
  bottom: -60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accommodation_facilities_desc {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 20px;
}

.theFacilities {
  display: flex;
  align-items: center;
}

.theFacilities > div {
  margin: 0px 10px 0px 10px;
}

.theFacilities > div p {
  margin: 0px;
  padding: 0px;
  font-size: 0.6em;
  font-weight: 600;
  color: grey;
}

.theFacilities .theIcon {
  height: 30px;
  width: 30px;
  background: #eee;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theFeaturedPhotos {
  display: flex;
  align-items: center;
}

.theFeaturedPhotos input {
  display: none;
}
.theFeaturedPhotos > label {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  margin: 0px 10px 0px 10px;
  cursor: pointer;
}
.theFeaturedPhotos > label img {
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 5px;
}

.cottages_article {
  margin-top: 150px;
}
.cottages_article h2 {
  font-size: 2.2em;
  font-weight: 600;
}

.cottages_article_container {
  height: 80vh;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.cottages_article_background {
  height: 200px;
  width: 100%;
  background-color: #d7eded;
  border-radius: 10px;
}

.cottages_grid_section {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.cottages_grid_section .cottages_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px;
}

.cottages_grid > div {
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.cottages_grid > div img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.cottages_grid > div > div:first-child {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.cottages_grid > div > div:nth-child(2) {
  width: 100%;
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
}

.room_desc p {
  padding: 0px;
  margin: 0px;
}

.surprises_article {
  padding-top: 100px;
  padding-bottom: 100px;
}

.surprises_article h2 {
  font-size: 2.2em;
  font-weight: 600;
}

.surprises_article p {
  font-size: 1em;
}
.surprises_article ul {
  font-size: 1em;
  list-style: none;
  padding: 0px;
}
.surprises_article ul li {
  font-size: 1em;
  color: grey;
  padding: 5px;
  display: flex;
  align-items: center;
}
.surprises_article ul li .iconSection {
  background-color: #eee;
  color: blue;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.surprises_article_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 450px;
}

.surprises_article_grid > div:nth-child(2) {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.surprises_article_grid > div:nth-child(2) .surprise_background {
  height: 200px;
  width: 100%;
  background-color: #d7eded;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.surprises_article_grid > div:nth-child(2) .surprise_video {
  position: absolute;
  top: 0px;
  left: 5%;
  right: 5%;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.surprise_video img {
  height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.surprise_hints {
  display: flex;
}

.surprise_hints input {
  display: none;
}

.surprise_hints > label {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.surprise_hints > label img {
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.relaxing_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.relaxing_grid > div:first-child {
  height: 400px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.relaxing_grid > div:first-child > div {
  height: 100%;
  border-radius: 10px;
}

.relaxing_grid > div:first-child img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.relaxing_grid > div:first-child .relax_image1 {
  display: flex;
  align-items: flex-end;
}

.relaxing_grid > div:first-child .relax_image3 {
  display: flex;
  align-items: flex-end;
}

.relaxing_grid > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.relaxing_article {
  padding-bottom: 100px;
}

.relaxing_article h2 {
  font-size: 2.2em;
  font-weight: 600;
}

.relaxing_article p {
  font-size: 1em;
}

.photo_article {
  width: 100%;
  background-color: #d7eded;
  padding-top: 50px;
  padding-bottom: 50px;
}

.photo_article img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
  border-radius: 10px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  #cf {
    height: 70vh;
  }
  .accommodation_article1_inner_section img {
    height: 70vh;
  }
  .accommodation_article1_description {
    padding: 10px;
  }

  .accommodation_article1_description h1 {
    font-size: 2em;
  }
  .accommodation_facilities_desc {
    width: 90%;
    display: block;
    padding: 20px;
  }
  .accommodation_facilities_desc > div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .accommodation_facilities_desc > div:nth-child(2) {
    margin-top: 10px;
  }
  .accommodation_facilities_desc h2 {
    display: none;
  }
  .cottages_article_container {
    background-color: #d7eded;
    height: 100%;
    border-radius: 10px;
    position: static;
    display: flex;
    align-items: flex-end;
  }

  .cottages_article_background {
    display: none;
  }
  .cottages_grid_section {
    position: static;
    width: 100%;
  }
  .cottages_grid_section .cottages_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 20px;
  }

  .surprises_article_grid {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
  }

  .surprises_article_grid > div:nth-child(2) {
    margin-top: 30px;
    height: 450px;
    position: relative;
    display: flex;
    align-items: flex-end;
  }

  .relaxing_grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .relaxing_grid > div:nth-child(2) {
    grid-auto-flow: column;
    grid-row: 1;
    text-align: center;
    padding: 0px 0px 20px 0px;
  }
  .cottages_article h2 {
    font-size: 1.9em;
    font-weight: 600;
  }

  .relaxing_article h2 {
    font-size: 1.9em;
    font-weight: 600;
  }

  .surprises_article h2 {
    font-size: 1.9em;
    font-weight: 600;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  #cf {
    height: 70vh;
  }
  .accommodation_article1_inner_section img {
    height: 70vh;
  }
  .accommodation_article1_description {
    padding: 10px;
  }

  .accommodation_article1_description h1 {
    font-size: 2em;
  }
  .accommodation_facilities_desc {
    width: 90%;
    display: block;
    padding: 20px;
  }
  .accommodation_facilities_desc > div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .accommodation_facilities_desc > div:nth-child(2) {
    margin-top: 10px;
  }
  .accommodation_facilities_desc h2 {
    display: none;
  }
  .cottages_article_container {
    background-color: #d7eded;
    height: 100%;
    border-radius: 10px;
    position: static;
    display: flex;
    align-items: flex-end;
  }

  .cottages_article_background {
    display: none;
  }
  .cottages_grid_section {
    position: static;
    width: 100%;
  }
  .cottages_grid_section .cottages_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 20px;
  }

  .surprises_article_grid {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
  }

  .surprises_article_grid > div:nth-child(2) {
    margin-top: 30px;
    height: 450px;
    position: relative;
    display: flex;
    align-items: flex-end;
  }

  .relaxing_grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .relaxing_grid > div:nth-child(2) {
    grid-auto-flow: column;
    grid-row: 1;
    text-align: center;
    padding: 0px 0px 20px 0px;
  }
  .cottages_article h2 {
    font-size: 1.9em;
    font-weight: 600;
  }

  .relaxing_article h2 {
    font-size: 1.9em;
    font-weight: 600;
  }

  .surprises_article h2 {
    font-size: 1.9em;
    font-weight: 600;
  }
}
</style>
